<template>
  <div>
      <div class="sctp-bg-white">
        <div style="width: 630px" class="sctp-pad-lr15 sctp-pad-tb10">
          <el-form :rules="formRules" size="small" ref="form" :model="form" label-width="120px">
            <el-form-item label="新登录密码" prop="password">
              <el-input type="password" v-model="form.password" style="width: 300px"></el-input>
            </el-form-item>
            <el-form-item label="确认登录密码" prop="rePassword">
              <el-input type="password" v-model="form.rePassword" style="width: 300px"></el-input>
            </el-form-item>
            <el-form-item label="邮箱验证码" prop="code">
              <div style="display: flex;justify-content: space-between">
                <el-input v-model="form.code" style="width: 300px">
                  <div slot="append">
                    <template v-if="!sendCodeConfig.send">
                      <el-button
                        @click="sendCodeConfig.sendCode"
                        :loading="!sendCodeConfig.canClick"
                      >
                        发送验证码
                      </el-button>
                    </template>
                    <template v-else>
                      <count-down @finish="sendCodeConfig.finishCountDown" format="ss秒后重试" :time="60 * 1000"></count-down>
                    </template>
                  </div>
                </el-input>
                  <span v-if="authEmailFlag" class="sctp-color-main" style="font-size: 11px;cursor: pointer"
                  @click="toAuthEmail()">您还没有邮箱认证，马上去认证</span>


              </div>
            </el-form-item>

            <el-form-item>
              <el-button type="primary" @click="onSubmitClick">提交</el-button>
            </el-form-item>
          </el-form>
        </div>
      </div>
  </div>
</template>

<script>

export default {
  name: "password",
  components: {
    CountDown: () => import('@CMP/common/CountDown')
  },
  data() {
    return {
      form: {
        password: null,
        rePassword: null,
        code: null
      },
      email:"",
      authEmailFlag:true,
      formRules: {
        password: [
          {required: true, message: '密码不能为空', trigger: ['blur', 'change']},
          {min: 6, max: 20, message: '密码长度在6~20位之间', trigger: ['blur', 'change']}
        ],
        rePassword: [
          {required: true, message: '请确认密码', trigger: ['blur', 'change']},
          {
            validator: (rule, value, callback) => {
              const {password} = this.form
              if (password === value) {
                callback()
              } else {
                callback(new Error('两次密码不一致'))
              }
            },
            trigger: ['blur', 'change']
          }
        ],
        code: [
          {required: true, message: '请输入验证码', trigger: ['blur', 'change']}
        ]
      },
      sendCodeConfig: {
        canClick: true,
        send: false,
        finishCountDown: () => {
          this.sendCodeConfig.send = false
        },
        sendCode: () => {
          // this.$refs['form'].validateField('', )
          this.sendCodeConfig.canClick = false
          this.sendCodeConfig.doSendCode()
            .then(res => {
              this.$message.success('发送成功，请注意查收!')
              this.sendCodeConfig.send = true
            })
            .catch(res => {
              this.sendCodeConfig.send = false
            })
            .finally(() => {
              this.sendCodeConfig.canClick = true
            })
        },
        doSendCode: () => {
          return this.$request.post({
            reqcode: '1114',
            reqdesc: '发送验证码',
            userId: this.user.userId
          })
        }
      }
    }
  },
  methods: {
    onSubmitClick() {
      this.$refs.form.validate(res => {
        if (res) {
          this.doSubmit()
        }
      })
    },
    doSubmit() {
      const {form: {password, code}} = this
      this.$request.post({
        reqcode: '1228',
        reqdesc: '修改密码',
        password: ChangeAccountPwd,
        code: code,
        userId: this.user.userId
      }).then(res => {
        this.$message.success('修改成功')
        this.$refs.form.resetFields();
      })
    },
    getEmail() {
      this.$request.post({
        reqcode: '1050',
        reqdesc: '获取用户邮箱',
        userId: this.user.userId
      }).then(res => {
        if (res.data){
          this.email = res.data
          this.authEmailFlag = false
        }
      })
    },
    toAuthEmail(){
      this.$router.push({
        path:'/user/userinfo/bindEmail'
      })
    }
  },
  created() {
    this.getEmail()
  }
}
</script>

<style scoped lang="scss">

</style>
